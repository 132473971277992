import { ActionsObservable, ofType } from 'redux-observable';
import {
  AuthActionType,
  ChangePasswordRequestAction,
  resetPasswordError,
  resetPasswordSuccess,
} from '../../actions';
import { mergeAll, switchMap } from 'rxjs/operators';
import { EpicDependencies } from '../epic-dependencies';
import { of } from 'rxjs';
import { push } from 'connected-react-router';

export const changePasswordEpic = (
  action$: ActionsObservable<changepasswordrequestaction>,
  state: {},
  { site }: EpicDependencies,
) =>
  action$.pipe(
    ofType(AuthActionType.ChangePasswordRequest),
    switchMap(async action => {
      try {
        const { token, newPassword } = action;
        // TODO: incorrect typecast here... this is a hack.
        await site.auth.changePassword({ token, newPassword } as any);
        return of(resetPasswordSuccess(), push('/admin/login'));
      } catch (e) {
        // TODO: remove this terrible shit.
        alert('Error resetting password. That reset URL may have expired.');
        return of(resetPasswordError(e));
      }
    }),
    mergeAll(),
  );
</changepasswordrequestaction>