import React, { Component } from 'react';
import Block from '../Block';
import Context from '../ToolbarContext';

import DecorationsContext, {
  DecorationsContextType,
} from '../DecorationsContext';

export type ToolbarProviderProps = {};

type State = {
  focused?: Block;
  hovered?: Block;
};

const initialState: State = Object.freeze({});

const ctx = Symbol();
const blur = Symbol();
const focus = Symbol();
const isIn = Symbol();
const mouseOut = Symbol();
const mouseOver = Symbol();

export default class ToolbarProvider extends Component<
  ToolbarProviderProps,
  State
> {
  state = initialState;

  [ctx]: DecorationsContextType;

  render() {
    const value = {
      blur: this[blur],
      focus: this[focus],
      isIn: this[isIn],
      mouseOut: this[mouseOut],
      mouseOver: this[mouseOver],
    };

    return (
      <decorationscontext.consumer>
        {context => {
          this[ctx] = context;
          return (
            <context.provider value="{value}">
              {this.props.children}
            </context.provider>
          );
        }}
      </decorationscontext.consumer>
    );
  }

  [mouseOver] = (block: Block) => {
    this.setState({ hovered: block });
  };

  [mouseOut] = (block: Block) => {
    if (this.state.hovered && this.state.hovered.getKey() === block.getKey()) {
      this.setState({ hovered: undefined });
    }
  };

  [blur] = (block: Block) => {
    if (this.state.focused && block.getKey() === this.state.focused.getKey()) {
      this.setState({ focused: undefined });
    }
  };

  [focus] = (block: Block) => {
    this.setState({ focused: block });
  };

  [isIn] = (block: Block) => {
    const { focused, hovered } = this.state;

    if (focused) {
      return block.node.contains(focused.getKey());
    }

    if (hovered) {
      return block.node.contains(hovered.getKey());
    }

    return false;
  };
}
