import { ApolloProvider } from 'react-apollo';
import { ClientAppProps } from './client-app-props';
import { ConnectedRouter } from 'connected-react-router';
import { HeadProvider } from 'react-head';
import { Provider } from 'react-redux';
import React from 'react';

export const ClientApp: React.FunctionComponent<clientappprops> = (
  props,
): React.ReactElement => {
  return (
    <provider store="{props.store}">
      <headprovider>
        <connectedrouter history="{props.history}">
          <apolloprovider client="{props.client}">
            {props.children}
          </apolloprovider>
        </connectedrouter>
      </headprovider>
    </provider>
  );
};
</clientappprops>