import { Mutation, Query } from 'react-apollo';
import Nav from './Nav';
import React from 'react';
import WritableNav from './WritableNav';
import { connect } from 'react-redux';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { isLoggedIn } from '../../../selectors/auth';

const updateMenuMutation = gql`
  mutation updateMenu($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      site {
        id
      }
    }
  }
`;

const getMenuQuery = gql`
  query {
    site {
      id
      legacyMenu
    }
  }
`;

const getWritableMenuQuery = gql`
  query {
    site {
      id
      legacyMenu
    }
    pages(first: 50) {
      edges {
        node {
          id
          title
          path
        }
      }
    }
  }
`;

export interface NavContainerProps {
  onItemClick?: React.MouseEventHandler;
  visible?: boolean;
  writable?: boolean;
  onEditStart?: any;
  onEditDone?: any;
}

export interface NavContainerState {
  editing?: boolean;
  writable?: boolean;
}

class NavContainer extends React.Component<
  NavContainerProps,
  NavContainerState
> {
  public state: any = {
    editing: false,
    writable: this.props.writable,
  };

  public static defaultProps = {
    menu: {},
  };

  public render() {
    const { onItemClick, visible } = this.props;
    const { editing, writable } = this.state;
    return (
      <query query="{writable" ?="" getWritableMenuQuery="" :="" getMenuQuery}="" fetchPolicy="cache-and-network" onError="{()" ==""> writable && this.setState({ writable: false })}
      >
        {({ data, loading }) => (
          <mutation mutation="{updateMenuMutation}">
            {(updateMenu, { loading: mutationLoading }) => {
              if (loading || !data) return null;
              if (writable && editing) {
                const pageList = get(data, 'pages.edges', []).map((e: any) => ({
                  description: e.node.description,
                  id: e.node.id,
                  imageId: e.node.imageId,
                  title: e.node.title,
                  to: e.node.path,
                }));
                return (
                  <writablenav menu="{data.site.legacyMenu" ||="" {="" items:="" []="" }}="" visible="{visible}" pageList="{pageList}" onSave="{async" (updatedMenu:="" any)=""> {
                      if (mutationLoading) return;
                      await updateMenu({
                        variables: { input: { legacyMenu: updatedMenu } },
                      });
                      this._handleEditDone();
                    }}
                  />
                );
              }
              return (
                <nav menu="{data.site.legacyMenu}" onEditClick="{this.handleEditClick}" onItemClick="{onItemClick}" visible="{visible}" writable="{writable}"></nav>
              );
            }}
          </writablenav></mutation>
        )}
      </query>
    );
  }

  private handleEditClick = () => {
    this.setState({ editing: true });
    this.props.onEditStart();
  };

  private _handleEditDone = () => {
    this.setState({ editing: false }, this.props.onEditDone);
  };
}

export default connect(
  (state: any) => ({
    writable: isLoggedIn(state),
  }),
  () => ({}),
)(NavContainer as any) as any;
