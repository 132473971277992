import { Reducer, combineReducers } from 'redux';
import { RouterState, connectRouter } from 'connected-react-router';
import { AdminAppAction } from '../../actions';
import { History } from 'history';
import { PublicAppState } from '../../state';
import { authReducer } from '../auth';

export interface CreatePublicAppReducerOptions {
  history: History;
}

export const createPublicAppReducer = ({
  history,
}: CreatePublicAppReducerOptions): Reducer<publicappstate, any=""> =>
  combineReducers({
    auth: authReducer,
    router: connectRouter(history) as Reducer<routerstate, AdminAppAction="">,
  });
</routerstate,></publicappstate,>