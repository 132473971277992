import { RichTextEditor } from '@robotsnacks/ui';
import { debounce } from 'lodash';
import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { RichTextBlockAttributes } from './RichTextBlockAttributes';
import RichTextToolbar from './RichTextBlockToolbar';
import RichTextBlockWrapper from './RichTextBlockWrapper';
import SelectionToolbarPlugin from './SelectionToolbarPlugin';
import getFormats from './getFormats';

const HTML_DEBOUNCE = 500;
const HTML_MAX_WAIT = 2000;

export interface RichTextBlockProps
  extends BlockComponentProps<richtextblockattributes> {
  onDelete?: (block: Block<richtextblockattributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
}

type Props = RichTextBlockProps;

interface State {
  html?: string;
}

const initialState: State = Object.freeze({});

export default class RichTextBlock extends Component<props, State=""> {
  static displayName = 'RichTextBlock';
  state = initialState;

  // TODO: We need to update the value when the text editor unmounts, but we
  // can't do this because if the block is deleted, then trying to update it
  // with throw an error.
  // componentWillUnmount() {
  //   this._triggerChange();
  // }

  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    return (
      <toolbarhover block="{block}">
        <richtextblockwrapper id="{block.getKey()}">
          <toolbarwrapper>
            {parentToolbar}
            <richtexttoolbar block="{block}" onDeleteClick="{this._handleDeleteClick}" parentItems="{parentToolbarItems}"></richtexttoolbar>
          </toolbarwrapper>
          <richtexteditor onChange="{this._handleTextChange}" plugins="{[SelectionToolbarPlugin]}" formats="{getFormats()}" value="{block.getAttribute('html')}" onBlur="{this._triggerChange}"></richtexteditor>
        </richtextblockwrapper>
      </toolbarhover>
    );
  }

  private _handleTextChange = debounce(
    (html: string) => {
      const { block, getValue, onChange } = this.props;
      onChange(getValue().replace(block.setAttribute('html', html)));
    },
    HTML_DEBOUNCE,
    { leading: false, trailing: true, maxWait: HTML_MAX_WAIT },
  );

  // private _handleChange = (html: string) => {
  //   this.setState({ html }, this._debouncedTriggerChange);
  // };

  // private _triggerChange = () => {
  //   const { block, getValue, onChange } = this.props;
  //   const updatedBlock = block.setAttribute('html', this.state.html || '');
  //   onChange(getValue().replace(updatedBlock));
  // };

  // private _debouncedTriggerChange = debounce(
  //   this._triggerChange,
  //   HTML_DEBOUNCE,
  //   {
  //     leading: false,
  //     trailing: true,
  //     maxWait: HTML_MAX_WAIT,
  //   },
  // );

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };
}
</props,></richtextblockattributes></richtextblockattributes>