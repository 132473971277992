import { noop } from 'lodash';
import { createContext } from 'react';

export type DecorationsContextType = {
  blur: (key: symbol | string) => void;
  disable: () => void;
  disabled: boolean;
  enable: () => void;
  enabled: boolean;
  focus: (key: symbol | string) => void;
  focusedKey: symbol | string | null;
  shouldDecorate: (key: symbol | string) => boolean;
};

export default createContext<decorationscontexttype>({
  blur: noop,
  disable: noop,
  disabled: false,
  enable: noop,
  enabled: true,
  focus: noop,
  focusedKey: null,
  shouldDecorate: () => true,
});
</decorationscontexttype>