import { AuthAction, AuthActionType } from '../../actions';
import { AuthState, AuthStatus } from '../../state';
import { Reducer } from 'redux';
import produce from 'immer';
import { serializeErrors } from '../serialize-errors';

const initialState: AuthState = {
  accessToken: null,
  errors: null,
  expiresAt: null,
  refreshToken: null,
  scope: [],
  status: null,
  tokenType: null,
};

export const authReducer: Reducer<authstate, AuthAction=""> = (
  state: AuthState = initialState,
  action: AuthAction,
): AuthState =>
  produce(state, (draft): void => {
    switch (action.type) {
      case AuthActionType.LoginError: {
        draft.errors = serializeErrors(action.errors);
        draft.status = AuthStatus.AuthenticationError;
        return;
      }

      case AuthActionType.LoginRequest: {
        draft.accessToken = null;
        draft.errors = null;
        draft.expiresAt = null;
        draft.refreshToken = null;
        draft.status = AuthStatus.AuthenticationRequested;
        draft.tokenType = null;
        return;
      }

      case AuthActionType.LoginSuccess: {
        draft.accessToken = action.accessToken;
        draft.expiresAt = new Date(
          Date.now() + action.expiresIn * 1000,
        ).toISOString();
        draft.refreshToken = action.refreshToken || null;
        draft.status = AuthStatus.AuthenticationSuccess;
        draft.tokenType = action.tokenType;
        return;
      }
    }
  });
</authstate,>