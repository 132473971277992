import Block from '../Block';
import { ImageBlockAttributes } from './ImageBlockAttributes';

const MAX_CONTENT_WIDTH = 1600;

const MAX_FULL_WIDTH = 2400;

export function getImageWidth(block: Block<imageblockattributes>) {
  const widthAttr = block.getAttribute('width');
  const format = block.getAttribute('_format');
  const originalWidth = block.getAttribute('originalWidth');

  if (widthAttr != null) {
    return widthAttr;
  }

  if (typeof format === 'string' && typeof originalWidth === 'number') {
    return computeImageWidth(format || 'content_width', originalWidth);
  }

  return MAX_CONTENT_WIDTH;
}

export function getImageHeight(block: Block<imageblockattributes>) {
  const heightAttr = block.getAttribute('height');
  const originalWidth = block.getAttribute('originalWidth');
  const originalHeight = block.getAttribute('originalHeight');
  const width = getImageWidth(block);

  if (typeof heightAttr === 'number') {
    return heightAttr;
  }

  if (typeof originalHeight === 'number' && typeof originalWidth === 'number') {
    return computeImageHeight(originalWidth, originalHeight, width);
  }

  return undefined;
}

function computeImageWidth(format: string, originalWidth: number): number {
  if (format === 'full_width') {
    return Math.round(Math.min(originalWidth, MAX_FULL_WIDTH));
  } else {
    return Math.round(Math.min(originalWidth, MAX_CONTENT_WIDTH));
  }
}

function computeImageHeight(
  originalWidth: number,
  originalHeight: number,
  width: number,
): number {
  const ar = originalHeight / originalWidth;
  return Math.round(ar * width);
}
</imageblockattributes></imageblockattributes>