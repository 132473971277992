import { createContext } from 'react';

export type BreakpointContextType = {
  breakpointNames: string[];
  breakpoints: { [name: string]: string };
  current: string;
  getMedia: (breakpoint: string) => string | null;
};

export default createContext<breakpointcontexttype>({
  breakpointNames: [],
  breakpoints: {},
  current: '',
  getMedia: () => null,
});
</breakpointcontexttype>