import Composer, {
  CardBlock,
  CodeBlock,
  ComposerState,
  DeckBlock,
  Delta,
  EventBlock,
  EventCardBlock,
  FeedBlock,
  GridBlock,
  GridItemBlock,
  ImageBlock,
  ReadOnlyCardBlock,
  ReadOnlyCodeBlock,
  ReadOnlyDeckBlock,
  ReadOnlyEventBlock,
  ReadOnlyEventCardBlock,
  ReadOnlyFeedBlock,
  ReadOnlyGridBlock,
  ReadOnlyGridItemBlock,
  ReadOnlyImageBlock,
  ReadOnlyRichTextBlock,
  ReadOnlySnipcartBlock,
  ReadOnlyStackBlock,
  ReadOnlyTitleBlock,
  RichTextBlock,
  SnipcartBlock,
  StackBlock,
  TitleBlock,
  card,
  code,
  deck,
  event,
  eventCard,
  feed,
  grid,
  gridItem,
  image,
  richText,
  snipcart,
  stack,
  title,
} from '@robotsnacks/composer';
import React, { SFC } from 'react';
import { identity, memoize } from 'lodash';
import ImageClient from '@robotsnacks/image-client';
import { openIde } from '../../../util/code-editor';
// import atob from 'atob';
// import getImagesCdnBaseUrl from '../utils/get-images-cdn-base-url';

const BASE_URL = ''; // getImagesCdnBaseUrl();

export interface PageComposerProps {
  className?: string;
  defaultPinterestDescription?: string;
  onChange?: (value: ComposerState) => void;
  readOnly?: boolean;
  siteId: string;
  value?: ComposerState;
}

const defaultValue = ComposerState.create(Delta.insert('STACK'));

const getUploadUri = () => {
  const uri = process.env.IMAGE_UPLOAD_URI;
  if (uri && uri.startsWith(':') && typeof window !== 'undefined') {
    const { protocol, hostname } = window.location;
    return `${protocol}//${hostname}${uri}`;
  } else if (uri) {
    return uri;
  }
  return '/api/v0/images';
};

const getBlockComponents = memoize((readOnly?: boolean) => {
  if (readOnly) {
    return {
      [card.name]: ReadOnlyCardBlock,
      [code.name]: ReadOnlyCodeBlock,
      [deck.name]: ReadOnlyDeckBlock,
      [event.name]: ReadOnlyEventBlock,
      [eventCard.name]: ReadOnlyEventCardBlock,
      [feed.name]: ReadOnlyFeedBlock,
      [grid.name]: ReadOnlyGridBlock,
      [gridItem.name]: ReadOnlyGridItemBlock,
      [image.name]: ReadOnlyImageBlock,
      [richText.name]: ReadOnlyRichTextBlock,
      [snipcart.name]: ReadOnlySnipcartBlock,
      [stack.name]: ReadOnlyStackBlock,
      [title.name]: ReadOnlyTitleBlock,
    };
  } else {
    return {
      [card.name]: CardBlock,
      [code.name]: CodeBlock,
      [deck.name]: DeckBlock,
      [event.name]: EventBlock,
      [eventCard.name]: EventCardBlock,
      [feed.name]: FeedBlock,
      [grid.name]: GridBlock,
      [gridItem.name]: GridItemBlock,
      [image.name]: ImageBlock,
      [richText.name]: RichTextBlock,
      [stack.name]: StackBlock,
      [snipcart.name]: SnipcartBlock,
      [title.name]: TitleBlock,
    };
  }
});

const openCodeEditor = (
  value: any,
  { discard, preview, save, onEditorClose }: any,
) => {
  return openIde({ value })
    .on('preview', preview)
    .on('SAVE_EDITOR_VALUE', (e: any) => save(e.value.value, e.compiledValue))
    .on('NOTIFY_OF_EDITOR_CLOSE', () => {
      onEditorClose();
      discard();
    });
};

export const PageComposer: React.FunctionComponent<pagecomposerprops> = ({
  className,
  defaultPinterestDescription,
  onChange,
  readOnly,
  siteId,
  value = defaultValue,
}) => {
  const [, oid, sid, eid] = '...'.split('.'); // atob(siteId).split('.');
  const imageClient = new ImageClient({
    basename: `/images`,
  });
  return (
    <composer 5="" 6="" 11="" 12="" className="{className}" breakpoints="{{" large:="" 'screen="" and="" (min-width:="" 1100px)',="" medium:="" 768px)="" (max-width:="" 1099px)',="" small:="" 767px)',="" }}="" attributes="{{" identity,="" blockComponents="{getBlockComponents(readOnly)}" blockTypes="{{" [card.name]:="" card,="" [code.name]:="" code,="" [deck.name]:="" deck,="" [event.name]:="" event,="" [eventCard.name]:="" eventCard,="" [feed.name]:="" feed,="" [grid.name]:="" grid,="" [gridItem.name]:="" gridItem,="" [image.name]:="" image,="" [richText.name]:="" richText,="" [snipcart.name]:="" snipcart,="" [stack.name]:="" stack,="" [title.name]:="" title,="" blockProps="{{" {="" waffle,="" imageClient,="" pageList,="" uploadUri:="" getUploadUri(),="" defaults:="" breakpoints:="" media:="" orientation:="" 'VERTICAL',="" height:="" 200,="" type:="" 'IMAGE',="" width:="" 300,="" },="" blocks:="" [="" ['card',="" [card,="" eventCard]],="" ['text',="" [richText,="" title]],="" ['media',="" [image]],="" ['advanced',="" [code,="" snipcart]],="" ],="" columnWidth:="" '4.166666667%',="" columns:="" 24,="" flow:="" 'right',="" rowHeight:="" 'minmax(50px,="" max-content)',="" size:="" 6,="" '8.333333333%',="" 12,="" eventList,="" 400,="" 600,="" 'down',="" {},="" ['layout',="" [grid,="" feed]],="" ['events',="" [event]],="" defaultPinterestDescription,="" client:="" openCodeEditor,="" onChange="{onChange}" value="{value}"></composer>
  );
};
</pagecomposerprops>