import {
  CHANGE_PASSWORD_MUTATION,
  ChangePasswordInput,
  ChangePasswordPayload,
  ChangePasswordVariables,
} from './change-password';
import {
  LOGIN_MUTATION,
  LoginInput,
  LoginPayload,
  LoginVariables,
} from './login';
import {
  RESET_PASSWORD_MUTATION,
  ResetPasswordInput,
  ResetPasswordPayload,
  ResetPasswordVariables,
} from './reset-password';
import { AuthTokensPayload } from './common';
import { Resource } from '../resource';

/**
 * SDK authentication resource for logging in, logging out, resetting and
 * changing passwords, etc.
 */
export class Auth extends Resource {
  /**
   * Change a user password using their current password and email address, or
   * a password reset token.
   *
   * @param input Input change password object.
   */
  public async changePassword(input: ChangePasswordInput): Promise<void> {
    const { data, errors } = await this.client.mutate<
      ChangePasswordPayload,
      ChangePasswordVariables
    >({
      mutation: CHANGE_PASSWORD_MUTATION,
      variables: { input },
    });
    if (data) {
      return;
    } else {
      throw errors;
    }
  }

  /**
   * Login a user using an API key or email and password combination.
   *
   * @param input API token or email and password input object.
   */
  public async login(input: LoginInput): Promise<authtokenspayload> {
    const { data, errors } = await this.client.mutate<
      LoginPayload,
      LoginVariables
    >({
      mutation: LOGIN_MUTATION,
      variables: { input },
    });
    if (data) {
      return data.login;
    } else {
      throw errors;
    }
  }

  /**
   * Triggers a password reset for the user with the given email address.
   *
   * @param input Input object with user email address.
   */
  public async resetPassword(input: ResetPasswordInput): Promise<void> {
    const { data, errors } = await this.client.mutate<
      ResetPasswordPayload,
      ResetPasswordVariables
    >({
      mutation: RESET_PASSWORD_MUTATION,
      variables: { input },
    });
    if (data) {
      return;
    } else {
      throw errors;
    }
  }
}
</void></authtokenspayload></void>