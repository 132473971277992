import { RichTextEditor, random } from '@robotsnacks/ui';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { RichTextBlockAttributes } from './RichTextBlockAttributes';
import RichTextBlockWrapper from './RichTextBlockWrapper';
import getFormats from './getFormats';

export interface ReadOnlyRichTextBlockProps
  extends BlockComponentProps<richtextblockattributes> {}

type Props = ReadOnlyRichTextBlockProps;

export default class ReadOnlyRichTextBlock extends Component<props> {
  private _key = random();

  public render() {
    const { block } = this.props;
    return (
      <richtextblockwrapper id="{block.getKey()}">
        <richtexteditor readOnly="" key="{this._key}" formats="{getFormats()}" value="{block.getAttribute('html')}"></richtexteditor>
      </richtextblockwrapper>
    );
  }
}
</props></richtextblockattributes>