import React from 'react';
import { Route } from 'react-router-dom';
import { StatusProps } from './status-props';

export const Status: React.FunctionComponent<statusprops> = ({
  status,
  children,
}): React.ReactElement | null => {
  return (
    <route render="{({" staticContext="" })=""> {
        if (staticContext) {
          (staticContext as { status?: number }).status = status;
        }
        return children;
      }}
    />
  );
};
</route></statusprops>