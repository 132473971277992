import {
  CREATE_PAGE_MUTATION,
  CreatePageInput,
  CreatePagePayload,
  CreatePageVariables,
} from './create-page';
import { GET_PAGE_QUERY, GetPageData, GetPageVariables } from './get-page';
import { Delta } from '@robotsnacks/composer';
import { Page } from './page';
import { PageNotFoundError } from './errors';
import { Resource } from '../resource';

/**
 *
 */
export class Pages extends Resource {
  /**
   * Transforms fields on the passed page data to a Page.
   * @param page The input page to transform.
   */
  private static transformPage(
    page: Page | Partial<getpagedata['page']> & { id: string },
  ): Page {
    return {
      content: null,
      description: '',
      images: [],
      title: '',
      path: null,
      tags: [],
      url: null,
      ...page,
      publishAt: page.publishAt ? new Date(page.publishAt) : null,
    };
  }

  /**
   * Creates a new page.
   * @param input Create page input.
   */
  public async create(input: CreatePageInput): Promise<page> {
    const { data } = await this.client.mutate<
      CreatePagePayload,
      CreatePageVariables
    >({
      mutation: CREATE_PAGE_MUTATION,
      variables: { input },
    });
    if (data) {
      return Pages.transformPage(data.createPage.page);
    } else {
      throw new Error('Error creating page...');
    }
  }

  /**
   * Duplicates an existing page.
   * @param pageId The ID of the page to duplicate.
   */
  public async duplicate(pageId: string): Promise<page> {
    const page = await this.getById(pageId);
    return this.create({
      content: page.content
        ? (Delta.duplicate(page.content as any) as any)
        : null,
    });
  }

  /**
   * Retrieves a page by its ID.
   * @param pageId The ID of the page to retrieve.
   */
  public async getById(pageId: string): Promise<page> {
    const { data } = await this.client.query<getpagedata, GetPageVariables="">({
      query: GET_PAGE_QUERY,
      variables: { id: pageId },
    });
    if (data) {
      return Pages.transformPage(data.page);
    } else {
      throw new PageNotFoundError();
    }
  }

  /**
   * Retrieves a page by its page.
   * @param path
   */
  public async getByPath(path: string): Promise<page> {
    const { data } = await this.client.query<getpagedata, GetPageVariables="">({
      query: GET_PAGE_QUERY,
      variables: { path },
    });
    if (data && data.page) {
      return Pages.transformPage(data.page);
    } else {
      throw new PageNotFoundError();
    }
  }
}
</getpagedata,></page></getpagedata,></page></page></page></getpagedata['page']>