import { AuthActionType } from './auth-action-type';

export interface LoginRequestAction {
  apiKey?: string;
  email?: string;
  password?: string;
  redirect?: string;
  type: AuthActionType.LoginRequest;
}

export const loginRequest = (
  input: Pick<loginrequestaction, 'apiKey'="" |="" 'email'="" 'password'="" 'redirect'="">,
): LoginRequestAction => ({
  ...input,
  type: AuthActionType.LoginRequest,
});
</loginrequestaction,>