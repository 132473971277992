import { Redirect, Route, Switch } from 'react-router-dom';
import { AdminLoginPage } from '../../common';
import { PageRoute } from '../page-route';
import React from 'react';
import { SearchPage } from '../search-page';

export const PublicAppRoutes: React.FunctionComponent = (): React.ReactElement => {
  return (
    <switch>
      <route exact="" path="/admin/login" component="{AdminLoginPage}"></route>
      <route exact="" path="/admin/page" component="{PageRoute}"></route>
      <route path="/admin" children="{()" ==""> <redirect to="/admin/login"></redirect>} />
      <route exact="" path="/search" component="{SearchPage}"></route>
      <route component="{PageRoute}"></route>
    </route></switch>
  );
};
