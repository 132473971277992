import { Record } from 'immutable';
import Block from '../Block';
import Delta, { genKey, toStringPath } from '../delta';
import Tree from '../tree';

const DEFAULT_RECORD = {
  attributes: {},
  tree: new Tree(),
};

export default class ComposerState extends Record(
  DEFAULT_RECORD,
  'ComposerValue',
) {
  static create(delta: Delta): ComposerState {
    return new ComposerState({
      tree: Tree.create(delta),
    });
  }

  apply(delta: Delta): ComposerState {
    return this.set('tree', this.tree.apply(delta));
  }

  del(block: Block<any>): ComposerState {
    return this.apply(Delta.del(block.getKey()));
  }

  insert(
    path: string | [string, string] | [string, number],
    type: string,
    attributes: { [name: string]: any } = {},
  ): ComposerState {
    return this.apply(
      Delta.insert({
        attributes,
        type,
        insert: toStringPath(path),
        key: genKey(),
      }),
    );
  }

  replace(block: Block<any>): ComposerState {
    return this.apply(
      Delta.replace(block.getKey(), {
        attributes: block.getAttributes(),
        type: block.getType(),
      }),
    );
  }
}
</any></any>