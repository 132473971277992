import { JssProvider, ThemeProvider } from 'react-jss';
import { ClientApp } from './client-app';
import { Theme as LegacyTheme } from '@robotsnacks/ui';
import { PublicApp } from '../components';
import Quill from 'quill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Site } from '@cardsetter/site-sdk';
import { createBrowserHistory } from 'history';
import { createPublicAppStore } from '../store';
import { getApolloCache } from './get-apollo-cache';
import { getPreloadedState } from './get-preloaded-state';
import { loadableReady } from '@loadable/component';

(window as any).Quill = Quill;

const history = createBrowserHistory();

const site = new Site({ cache: getApolloCache('public'), uri: '/graphql' });

const store = createPublicAppStore({
  epicDependencies: { client: site.client, site },
  history,
  preloadedState: getPreloadedState('public'),
});

// If running inside an iframe hook into the parent's React devtools.
if (window.parent !== window) {
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__ =
    window.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__;
}

window.addEventListener('dragover', e => e.preventDefault(), false);
window.addEventListener('drop', e => e.preventDefault(), false);

function patchAnchor(anchor: HTMLAnchorElement): void {
  if (typeof URL === 'undefined' || typeof URLSearchParams === 'undefined') {
    return;
  }

  const href = anchor.getAttribute('href');

  if (href == null) {
    return;
  }

  const url = new URL(href, window.location.href);
  const insideIframe = window.parent !== window;

  // If the link is to a page on the same website, then append viewport when
  // inside an iframe
  if (url.origin === window.origin && insideIframe) {
    if (!url.searchParams.has('viewport')) {
      url.searchParams.set('viewport', 'true');
      anchor.href = url.toString();
    }
    return;
  }

  // If the origin is different, then update target.
  if (url.origin !== window.origin) {
    anchor.setAttribute('target', '_blank');
  }
}

loadableReady((): void => {
  ReactDOM.hydrate(
    <clientapp client="{site.client}" history="{history}" store="{store" as="" any}="">
      <jssprovider>
        <themeprovider theme="{LegacyTheme.create().toJSON()}">
          <publicapp></publicapp>
        </themeprovider>
      </jssprovider>
    </clientapp>,
    document.getElementById('root'),
  );

  document.querySelectorAll('a').forEach(anchor => {
    patchAnchor(anchor as HTMLAnchorElement);
  });
});
